<!--事業所も含めるしれない-->
<template>
  <div>

    <MyBeeSearchBar @goodPaging="goodPaging" @changePage="changePage" @devSearch="devSearch"
                    :curr-page.sync="pagingParams.currPage"
                    :per-page.sync="pagingParams.perPage"
                    :per-page-list="perPageList"
                    :pages="pages"
                    :search-bar-place-holder="searchBarPlaceHolder"
                    :keyword.sync="pagingParams.keyword"></MyBeeSearchBar>
    <BeeGridTable
        ref="bee"
        border
        stripe
        :showFilter="false"
        :columns="columns"
        :loading="isLoading"
        :data="rows"
        :showPager="false"
        :maxHeight="gridCardHeight"
        highlightRow
        no-data-text="データがありません。"
        no-filtered-data-text=""
        :context="context"
        :span-method="customSpan"
        :showVerticalScrollBar="false"
        :showHorizontalScrollBar="false">
      <!-- HEADER ソート可能にするために 必要-->
<!--
      {title: 'No', key: 'no',slot:"no",headSlot:"noHead",     width: '120px', sortable: false},
      {title: '販売店ID', key: 'agencyId',slot:"agencyId",headSlot:"agencyIdHead",     width: '120px'},
      {title: '販売店の名称', key: 'agencyName',slot:"agencyName",headSlot:"agencyNameHead",    },
      {title: '販売店住所', key: 'agencyAddress',slot:"agencyAddress",headSlot:"agencyAddressHead",    sortable: false},
      {title: '事業所ID', key: 'agencyBranchId',slot:"agencyBranchId",headSlot:"agencyBranchIdHead",     width: '100px'},
      {title: '事業所名', key: 'agencyBranchName',slot:"agencyBranchName",headSlot:"agencyBranchNameHead",    sortable: false},
      {title: '', key: 'after',slot:"after",headSlot:"afterHead",    width: '260px',sortable: false}-->

      <template slot-scope="{ column,index }" slot="agencyIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="agencyNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="agencyAddressHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="agencyBranchIdHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>
      <template slot-scope="{ column,index }" slot="agencyBranchNameHead"><MyBeeHeader :column="column" :index="index" @sort="sort"/></template>

      <!-- cell -->
      <template slot-scope="{ row,index }" slot="no"><div class="text-left">{{row.no}}</div></template>
      <template slot-scope="{ row,index }" slot="agencyId"><div class="text-left">{{row.agencyId}}</div></template>
      <template slot-scope="{ row,index }" slot="agencyName"><div class="text-left">{{row.agencyName}}</div></template>
      <template slot-scope="{ row,index }" slot="agencyAddress"><div class="text-left">{{row.agencyAddress}}</div></template>
      <template slot-scope="{ row,index }" slot="agencyBranchId"><div class="text-left">{{row.agencyBranchId}}</div></template>
      <template slot-scope="{ row,index }" slot="agencyBranchName"><div class="text-left">{{row.agencyBranchName}}</div></template>


      <template slot-scope="{ row,index }" slot="after">
        <div class="float-left">
          <CButton color="success py-0" size="sm" class="mr-1" @click="setAgencyWithBranch(row)" v-show="row.agencyBranchId">指定</CButton>
          <div v-show="row.agencyBranchId == null">
            事業所が登録されていません。
          </div>
        </div>
      </template>
    </BeeGridTable>
  </div>

</template>

<script>

import BeeMix from '@/views/aprod/js/mixin/beeUtils'
import MyBeeHeader from "@/component/parts/MyBeeHeader";
import MyBeeSearchBar from "@/component/parts/MyBeeSearchBar";
export default {
  name: 'AgencyWithBranch',
  components:{
    MyBeeHeader, MyBeeSearchBar
  },
  mixins: [BeeMix],
  props: {
    isShow: { default: false, type: Boolean },
  },
  mounted() {
    // this.goodPaging();
  },
  data: () => {
    return {
      searchBarPlaceHolder:"販売店名、事業所名で検索",
      columns: [
        {title: '販売店ID', key: 'agencyId',slot:"agencyId",headSlot:"agencyIdHead",align:"center"},
        {title: '販売店名', key: 'agencyName',slot:"agencyName",headSlot:"agencyNameHead",align:"center"    },
        {title: '販売店住所', key: 'agencyAddress',slot:"agencyAddress",headSlot:"agencyAddressHead",align:"center"},
        {title: '事業所ID', key: 'agencyBranchId',slot:"agencyBranchId",headSlot:"agencyBranchIdHead",align:"center"},
        {title: '事業所名', key: 'agencyBranchName',slot:"agencyBranchName",headSlot:"agencyBranchNameHead",align:"center"},
        {title: ' ', key: 'after',slot:"after",align:"center"}
      ],
      isCollapsed:true
    };
  },
  methods: {
    filterUser:function(row){
      this.$emit("filterAgencyUser",row);
    },
    // addAgencyUser:function(row){
    //   this.$emit("addAgencyUser",row);
    // },
    setAgencyWithBranch:function(row){
        this.$emit("setAgencyWithBranch",row);
    },
    goodPaging: function (isPageChange) {
      if (!isPageChange) this.pagingParams.currPage = 1;//ページが以外が変わったら最初から検索する
      this.isLoading = true;
      this.axios.post('/api/agency/agencyAgencyBranchSearchPaging', this.pagingParams, {headers: {'Content-Type': 'application/json'}}).then(response => {
        this.totalRecords = response.data.result.totalElements;
        this.rows = response.data.result.content;
        //ページ件数
        this.pages = response.data.result.totalPages;
        this.topLeft();
      }).catch(error => console.log(error)
      ).finally(() => this.isLoading = false);
    }
  },
  watch:{
    isShow:function(){
      if(this.isShow){
       this.goodPaging();
      }else{
        this.pagingParams.keyword = null;
      }
    }
  }
}

</script>
